export default [
  {
    name: 'Dashboard',
    icon: 'nc-icon nc-chart-bar-32',
    path: '/admin/dashboard',
    isAdmin: true,
  },
  {
    name: 'Payment Settings',
    icon: 'nc-icon nc-bank',
    path: '/admin/bank-account',
    isAdmin: true,
  },
  {
    name: 'User Account',
    icon: 'nc-icon nc-badge',
    path: '/admin/user-account',
    isAdmin: true,
  },
  {
    name: 'Products',
    icon: 'nc-icon nc-box-2',
    isAdmin: true,
    children: [
      {
        name: 'Brand',
        path: '/admin/brand',
        isAdmin: true,
      },
      {
        name: 'Category',
        path: '/admin/category',
        isAdmin: true,
      },
      {
        name: 'Products',
        path: '/admin/products',
        isAdmin: true,
      },
    ]
  },
  {
    name: 'Orders',
    icon: 'nc-icon nc-cart-simple',
    path: '/admin/orders',
    isAdmin: true,
  },
  {
    name: 'Report',
    icon: 'nc-icon nc-single-copy-04',
    isAdmin: true,
    children: [
      {
        name: 'Product Summary',
        path: '/admin/product-summary',
        isAdmin: true,
      },
      {
        name: 'Order Summary',
        path: '/admin/order-summary',
        isAdmin: true,
      },
      // {
      //   name: 'Order Products Summary',
      //   path: '/admin/order-products-summary',
      //   isAdmin: true,
      // },
    ]
  },

  {
    name: 'Dashboard',
    icon: 'nc-icon nc-chart-bar-32',
    path: '/user/dashboard',
    isAdmin: false,
  },
  {
    name: 'Orders',
    icon: 'nc-icon nc-cart-simple',
    path: '/user/orders',
    isAdmin: false,
  },
  {
    name: 'Report',
    icon: 'nc-icon nc-single-copy-04',
    isAdmin: false,
    children: [
      {
        name: 'Order Summary',
        path: '/user/order-summary',
        isAdmin: false,
      },
      // {
      //   name: 'Order Products Summary',
      //   path: '/user/order-products-summary',
      //   isAdmin: false,
      // },
    ]
  },

  // {
  //   name: 'Dashboard',
  //   icon: 'nc-icon nc-bank',
  //   path: '/admin/overview'
  // },
  // {
  //   name: 'Pages',
  //   icon: 'nc-icon nc-book-bookmark',
  //   children: [
  //     {
  //       name: 'Timeline',
  //       path: '/pages/timeline'
  //     },
  //     {
  //       name: 'User Profile',
  //       path: '/pages/user'
  //     },
  //     {
  //       name: 'Login',
  //       path: '/login'
  //     },
  //     {
  //       name: 'Register',
  //       path: '/register'
  //     },
  //     {
  //       name: 'Lock',
  //       path: '/lock'
  //     }
  //   ]
  // },
  // {
  //   name: 'Components',
  //   icon: 'nc-icon nc-layout-11',
  //   children: [{
  //     name: 'Buttons',
  //     path: '/components/buttons'
  //   },
  //   {
  //     name: 'Grid System',
  //     path: '/components/grid-system'
  //   },
  //   {
  //     name: 'Panels',
  //     path: '/components/panels'
  //   },
  //   {
  //     name: 'Sweet Alert',
  //     path: '/components/sweet-alert'
  //   },
  //   {
  //     name: 'Notifications',
  //     path: '/components/notifications'
  //   },
  //   {
  //     name: 'Icons',
  //     path: '/components/icons'
  //   },
  //   {
  //     name: 'Typography',
  //     path: '/components/typography'
  //   }]
  // },
  // {
  //   name: 'Forms',
  //   icon: 'nc-icon nc-ruler-pencil',
  //   children: [{
  //     name: 'Regular Forms',
  //     path: '/forms/regular'
  //   },
  //   {
  //     name: 'Extended Forms',
  //     path: '/forms/extended'
  //   },
  //   {
  //     name: 'Validation Forms',
  //     path: '/forms/validation'
  //   },
  //   {
  //     name: 'Wizard',
  //     path: '/forms/wizard'
  //   }
  //   ]
  // },
  // {
  //   name: 'Table List',
  //   icon: 'nc-icon nc-single-copy-04',
  //   collapsed: true,
  //   children: [{
  //     name: 'Regular Tables',
  //     path: '/table-list/regular'
  //   },
  //   {
  //     name: 'Extended Tables',
  //     path: '/table-list/extended'
  //   },
  //   {
  //     name: 'Paginated Tables',
  //     path: '/table-list/paginated'
  //   }
  //   ]
  // },
  // {
  //   name: 'Maps',
  //   icon: 'nc-icon nc-pin-3',
  //   children: [{
  //     name: 'Google Maps',
  //     path: '/maps/google'
  //   },
  //   {
  //     name: 'Full Screen Maps',
  //     path: '/maps/full-screen'
  //   },
  //   {
  //     name: 'Vector Maps',
  //     path: '/maps/vector-map'
  //   }
  //   ]
  // },
  // {
  //   name: 'Widgets',
  //   icon: 'nc-icon nc-box',
  //   path: '/admin/widgets'
  // },
  // {
  //   name: 'Charts',
  //   icon: 'nc-icon nc-chart-bar-32',
  //   path: '/charts'
  // },
  // {
  //   name: 'Calendar',
  //   icon: 'nc-icon nc-calendar-60',
  //   path: '/calendar'
  // },
]
