<template>
  <div class="login-page">
    <!-- <app-navbar></app-navbar> -->
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page section-image">
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="col-lg-4 col-md-6 ml-auto mr-auto">
              <form @submit.prevent="login">
                <card type="login">
                  <h3 slot="header" class="header text-center"><img src="/static/img/logo.png"></h3>

                  <fg-input v-model="form.username" addon-left-icon="nc-icon nc-single-02"
                    placeholder="User Name..."></fg-input>

                  <fg-input v-model="form.password" addon-left-icon="nc-icon nc-key-25" placeholder="Password"
                    type="password"></fg-input>

                  <br>

                  <!-- <p-checkbox>
                    Subscribe to newsletter
                  </p-checkbox> -->

                  <p-button native-type="submit" slot="footer" type="warning" round block class="mb-3"
                    style="background-color:#F2F1F0; color:#000"
                  >
                    Login
                  </p-button>
                </card>
              </form>
            </div>
          </div>
        </div>
        <!-- <app-footer></app-footer> -->
        <!-- <div class="full-page-background" style="background-image: url(static/img/background/background-2.jpg) "></div> -->
        <div class="full-page-background" style="background-color:#F2F1F0"></div>
        
      </div>
    </div>
  </div>
</template>
<script>
  import { Card, Checkbox, Button } from 'src/components/UIComponents';
  // import AppNavbar from './Layout/AppNavbar'
  // import AppFooter from './Layout/AppFooter'
  import NotificationTemplate from 'src/components/Dashboard/Views/Components/NotificationTemplate'
  import axios from 'axios'

  export default {
    components: {
      Card,
      // AppNavbar,
      // AppFooter,
      [Checkbox.name]: Checkbox,
      [Button.name]: Button,
      NotificationTemplate,
    },
    data() {
      return {
        form: {
          username: '',
          password: ''
        },
        apiUrl: process.env.NODE_ENV === 'production'?'https://www.hbrxws.com/api':'http://localhost/hbrxws/api',
      }
    },
    mounted (){
      const token = localStorage.getItem('authToken');

      if(token!=''){
        axios.post(
          this.apiUrl+'/user?action=getUserDetail', { token:token },
          { headers: { 'Content-Type': 'multipart/form-data' } }
        )
        .then(response => {
          switch(response.data.role){
            case 'admin': this.$router.push('/admin/dashboard'); break;
            case 'user': this.$router.push('/user/dashboard'); break;
          }
        });
      }
    },
    methods: {
      toggleNavbar() {
        document.body.classList.toggle('nav-open')
      },
      closeMenu() {
        document.body.classList.remove('nav-open')
        document.body.classList.remove('off-canvas-sidebar')
      },
      async login() {
        await axios.post(
          this.apiUrl+'/user?action=login', this.form,
          { headers: { 'Content-Type': 'multipart/form-data' } }
        )
        .then(response => {
          if(response.data.success==true){
            localStorage.setItem('authToken', response.data.token);

            switch(response.data.role){
              case 'admin': this.$router.push('/admin/dashboard'); break;
              case 'user': this.$router.push('/user/dashboard'); break;
            }
          }else{
            this.$notify({
              component: NotificationTemplate,
              icon: 'nc-icon nc-app',
              horizontalAlign: 'center',
              verticalAlign: 'bottom',
              type: 'danger',
              message: 'Login Error !'
            })
          }
        })
        .catch(error => {
          this.$notify({
            component: NotificationTemplate,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'center',
            verticalAlign: 'bottom',
            type: 'danger',
            message: 'API Error !'
          })
        });
      },
      // saveName() {
      //   // Save the entered name to localStorage
      //   localStorage.setItem('savedName', this.userName);
      //   this.savedName = this.userName;
      // },
      // clearName() {
      //   // Clear the saved name from localStorage
      //   localStorage.removeItem('savedName');
      //   this.savedName = '';
      //   this.userName = '';
      // }
    },
    beforeDestroy() {
      this.closeMenu()
    }
  }
</script>
<style>
</style>
