<template>
  <!-- <span>Welcome to <b>Paper Dashboard</b> - a beautiful freebie for every web developer.</span> -->
  <span>
    <b>{{ message }}</b>
  </span>
</template>
<script>
  export default {
    props: {
      message: String,
      default: ''
    },
    data () {
      return {
        // message : ''
      }
    }
  }
</script>
<style>
</style>
