<template>
  <div class="card">
    <div class="card-header">
      <h4 class="title">Edit Profile</h4>
    </div>
    <div class="card-body">
      <form>
        <div class="row">
          <!-- <div class="col-md-5">
            <fg-input type="text" label="Company" :disabled="true" placeholder="Paper dashboard" v-model="user.company">
            </fg-input>
          </div> -->
          <div class="col-md-6">
            <fg-input type="text" label="Username" placeholder="" v-model="user.username" disabled></fg-input>
          </div>
          <div class="col-md-6">
            <fg-input type="password" label="Password" placeholder="" v-model="user.password"></fg-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <fg-input type="text" label="First Name" placeholder="" v-model="user.firstname"></fg-input>
          </div>
          <div class="col-md-6">
            <fg-input type="text" label="Last Name" placeholder="" v-model="user.lastname"></fg-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <fg-input type="text" label="Address" placeholder="" v-model="user.address"></fg-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <fg-input type="email" label="E-mail" placeholder="" v-model="user.email"></fg-input>
          </div>
          <div class="col-md-6">
            <fg-input type="text" label="Tel." placeholder="" v-model="user.tel"></fg-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <fg-input type="text" label="Remark" placeholder="" v-model="user.remark"></fg-input>
          </div>
        </div>
        <!-- <div class="row">
          <div class="col-md-4">
            <fg-input type="text"
                      label="City"
                      placeholder="City"
                      v-model="user.city">
            </fg-input>
          </div>
          <div class="col-md-4">
            <fg-input type="text"
                      label="Country"
                      placeholder="Country"
                      v-model="user.country">
            </fg-input>
          </div>
          <div class="col-md-4">
            <fg-input type="number"
                      label="Postal Code"
                      placeholder="ZIP Code"
                      v-model="user.postalCode">
            </fg-input>
          </div>
        </div> -->

        <!-- <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label>About Me</label>
              <textarea rows="5" class="form-control border-input"
                        placeholder="Here can be your description"
                        v-model="user.aboutMe">

              </textarea>
            </div>
          </div>
        </div> -->
        <div class="text-center">
          <button type="submit" class="btn btn-info btn-fill btn-wd" @click.prevent="updateProfile">
            Update Profile
          </button>
        </div>
        <div class="clearfix"></div>
      </form>
    </div>
  </div>
</template>
<script>
import NotificationTemplate from 'src/components/Dashboard/Views/Components/NotificationTemplate'
import axios from 'axios'

export default {
  components: {
    NotificationTemplate
  },
  data () {
    return {
      user: {
        id: 0,
        username: '',
        firstname: '',
        lastname: '',
        address: '',
        email: '',
        tel: '',
        remark: ''
      },
      apiUrl: process.env.NODE_ENV === 'production'?'https://www.hbrxws.com/api':'http://localhost/hbrxws/api',
    }
  },
  mounted (){
    const token = localStorage.getItem('authToken');

    axios.post(
      this.apiUrl+'/user?action=getUserDetail', { token:token },
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
    .then(response => {
      this.user = response.data;
    });
  },
  methods: {
    async updateProfile () {
      const token = localStorage.getItem('authToken');

      let userID = await axios.post(
        this.apiUrl+'/user?action=getUserID', { token:token },
        { headers: { 'Content-Type': 'multipart/form-data' } }
      )
      .then(response => {
        this.user.id = response.data.id;
      });
      
      try {
        // Make a POST request using Axios
        console.log(this.user);

        const response = await axios.post(
          this.apiUrl+'/userAccount?action=updateUserAccount', this.user,
          { headers: { 'Content-Type': 'multipart/form-data' } }
        );

        this.$notify({
          component: NotificationTemplate,
          icon: 'nc-icon nc-app',
          horizontalAlign: 'center',
          verticalAlign: 'bottom',
          type: 'success',
          message: 'บันทึกเรียบร้อยแล้ว'
        })
      } catch (error) {
        this.$notify({
          component: NotificationTemplate,
          icon: 'nc-icon nc-app',
          horizontalAlign: 'center',
          verticalAlign: 'bottom',
          type: 'danger',
          message: 'Error !'
        })
      }
    },
  }
}

</script>
<style>

</style>
